import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { returnByHistory } from '../../tools';
import './Swipe.scss';

const Swipe = () => {
  const history = useHistory();

  const clientAuth = useSelector((state) => state.common.clientAuth);

  const handleGoBack = () => {
    const pathnameSegments = window.location.pathname;
    const destination = returnByHistory(pathnameSegments, clientAuth);
    setTimeout(() => {
      history.push(destination);
    }, 10);
  };

  useEffect(() => {
    const messageListenerIOS = window.addEventListener('message', (event) => {
      const swipeElement = document.querySelector('.swipe');

      if (event.data === 'swipeStarted') {
        swipeElement.classList.add('swipe-started');
      } else {
        swipeElement?.classList.remove('swipe-started');
      }
      if (event.data === 'swipeFinished') {
        swipeElement.classList.add('swipe-finished');
        handleGoBack();
        setTimeout(() => {
          swipeElement.classList.remove('swipe-finished');
        }, 300);
      }
      if (event.data === 'swipeCanceled') {
        swipeElement.classList.add('swipe-canceled');
        setTimeout(() => {
          swipeElement.classList.remove('swipe-canceled');
        }, 300);
      }
    });

    const messageListenerAndroid = document.addEventListener('message', (event) => {
      if (event.data === 'goBackAndroid') {
        handleGoBack();
        return;
      }
    });

    return () => {
      window.removeEventListener('message', messageListenerIOS);
      document.removeEventListener('message', messageListenerAndroid);
    };
  }, []);

  return (
    <div className="swipe">
      <div className="inner">
        <div className="top-arrow" />
        <div className="bottom-arrow" />
      </div>
    </div>
  );
};

export default Swipe;
