import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import clsx from 'clsx';
import { Suspense, lazy } from 'react';

import SwiperRefresh from './components/SwipeRefresh';
import AuthControl from './components/AuthControl';
import MainLoader from './components/MainLoader';
import {
  useControllView,
  useClientInfo,
  useLanguage,
  useAuthData,
  useConnectSocket,
  useListenMessages,
  useListenWebviewMessages,
  useCheckForGift,
  useSetLanguageByIpAddress,
  useCheckFirstLoginBonus,
  useCheckHotlineBonus,
} from './tools/hooks';
import AppSuggestionNotification from './components/AlertNotificationCustom/AppSuggestionNotification/AppSuggestionNotification';
import Swipe from './components/Swipe';
import { Toaster } from './components/Toast';
import LanguageDetector from './components/LanguageDetector/LanguageDetector';
import PageTitleHandler from './PageTitleHandler';

const ClientMenu = lazy(() => import('./components/ClientMenu/ClientMenu'));
const Modals = lazy(() => import('./Modals'));
const Confetti = lazy(() => import('./components/Confetti'));
const Ticker = lazy(() => import('./components/Ticker'));
const RouterSwitch = lazy(() => import('./RouterSwitch'));

export default function App() {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const { mainLoader, mobSidebar } = useSelector((state) => state.ui);
  const appPrepared = useSelector((state) => state.common.appPrepared);

  useControllView();
  useClientInfo();
  useSetLanguageByIpAddress();
  useLanguage();
  useAuthData();
  useConnectSocket();
  useListenMessages();
  useListenWebviewMessages();
  useCheckForGift();
  useCheckFirstLoginBonus();
  useCheckHotlineBonus();

  if (clientAuth && !appPrepared)
    return (
      <Router>
        <AuthControl />
        <MainLoader show={true} />
      </Router>
    );

  return (
    <Suspense fallback={<MainLoader show={true} />}>
      <Router>
        <AuthControl />
        <LanguageDetector />
        <main className={clsx('ant-layout layout', clientInfo && 'clientAuth')}>
          {clientInfo?._id && clientAuth?.phoneVerified && clientAuth?.policyConfirmed ? <ClientMenu /> : null}
          <Ticker />
          <section className={clsx(clientAuth && 'ant-layout-content', mobSidebar && clientInfo && 'mobDisabled')}>
            <SwiperRefresh />
            <RouterSwitch />
            <PageTitleHandler />
            <AppSuggestionNotification />
          </section>
        </main>
        <MainLoader show={mainLoader} />
        <Modals />
        <Swipe />
        <Toaster />
        <Confetti />
      </Router>
    </Suspense>
  );
}
