import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { CLIENT_TOKEN } from 'common_constants/business';
import { CLIENT_ROUTES, OPEN_ROUTES } from 'common_constants/routes';
import { ADD_CLIENT_EMAIL, FIRST_LOGIN_AUTO_PAY, QUESTIONNAIRE_HOTLINE } from 'common_constants/modals';

import { setClientEmailVerified, setModal, setMissingEmail, setClientPhoneVerified, setClientPolicyConfirmed } from '../../store/commonReducer';
import { clientLogOut, error, request } from '../../tools';
import { CheckClientInfo } from '../../tools/hooks';

import checkQueryParams from './checkQueryParams';
import { store } from '../../store/store';

export default function AuthControl() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);

  const clientRoutesArr = Object.values(CLIENT_ROUTES);
  const HotlineMeetInfo = pathname.match(/^\/.{24}$/);
  const CertificatePage =
    pathname.match(/^\/certificate\/.{10}$/) ||
    pathname.match(/^\/certificate\/.{9}$/) ||
    pathname.match(/^\/cert\/.{10}$/) ||
    pathname.match(/^\/cert\/.{9}$/);
  const CertificateLocated = CertificatePage ? CLIENT_ROUTES.ANONIM_CERTIFICATE_.route : null;
  const LawyerCV = pathname.match(/^\/cv\/./);
  const BkShortLinkPage = pathname.match(/^\/bk/);
  const inviteLinkToken = localStorage.getItem('friendShareLinkToken');

  const openRoute = OPEN_ROUTES.includes(pathname) || HotlineMeetInfo || CertificateLocated || LawyerCV;

  const clientSequredPage = !OPEN_ROUTES.find((route) => {
    if (route.includes('/:') && route !== '/:hash') {
      const routeWithoutParams = route.split('/:')[0];
      return pathname.startsWith(routeWithoutParams);
    }
    return pathname === route;
  });

  const checkClientToken = () => {
    if (!clientAuth) return;

    const token = window.localStorage.getItem(CLIENT_TOKEN);

    fetch(process.env.REACT_APP_API + '/auth/checkClientToken', {
      body: JSON.stringify({}),
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) {
          clientLogOut();
          return;
        }

        dispatch(
          setClientEmailVerified({
            email: res.email,
            emailVerified: res.emailVerified,
          }),
        );
        dispatch(
          setClientPhoneVerified({
            ph: res.ph,
            phoneVerified: res.phoneVerified,
            verificationSmsSendTime: res.verificationSmsSendTime,
          }),
        );
        dispatch(setClientPolicyConfirmed({ policyConfirmed: res.policyConfirmed }));
        if (res.phoneVerified && res.policyConfirmed && !res.emailVerified) {
          dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
          dispatch(setMissingEmail(true));
        }
      })
      .catch(clientLogOut);
  };

  const _useClientInfo = () => {
    if (!clientAuth || !clientAuth._id) return;
    CheckClientInfo(dispatch);
  };

  const setInvitedClient = () => {
    request(
      '/partners/setInvitedFriend',
      {
        inviteLinkToken,
      },
      () => {
        localStorage.removeItem('friendShareLinkToken');
      },
      error,
    );
  };

  useEffect(() => {
    if (clientAuth) checkClientToken();
  }, []);

  checkQueryParams(clientAuth, history, dispatch);

  // Redirect from Login pages

  useEffect(() => {
    if (clientAuth && (pathname === CLIENT_ROUTES.CLIENT_LOGIN.route || pathname === CLIENT_ROUTES.CLIENT_REGISTRATION.route)) {
      const makeAppointment = localStorage.getItem('makeAppointment');
      const bkConsultation = sessionStorage.getItem('showBkUnauthError') === 'true';
      const qualityAssessment = localStorage.getItem('qualityAssessment');

      if (makeAppointment) {
        localStorage.removeItem('makeAppointment');
        history.push(CLIENT_ROUTES.CLIENT_HOTLINE.route);
      } else if (bkConsultation) {
        sessionStorage.removeItem('showBkUnauthError');
        history.push(CLIENT_ROUTES.CLIENT_QUICK_CHAT.route);
      } else if (qualityAssessment) {
        localStorage.removeItem('qualityAssessment');
        dispatch(setModal({ name: QUESTIONNAIRE_HOTLINE, data: { H: qualityAssessment } }));
      } else {
        history.push(CLIENT_ROUTES.CLIENT_HOME.route);
      }
      _useClientInfo();
    }
  }, [pathname, clientAuth, clientInfo]);

  useEffect(() => {
    if (!clientAuth?._id || !clientInfo?._id) return;

    if (!clientAuth?.emailVerified || !clientAuth?.phoneVerified) return; //* Блок для 2 модалок верифікації

    if (clientInfo?.contracts_for_questionnaire?.[0]) return; //* Блок для 2 анкет

    if (sessionStorage.getItem('modalFirst') === 'true') return; //* Блок останньої появи модалки під час сесії

    dispatch(setModal({ name: FIRST_LOGIN_AUTO_PAY }));
  }, [clientAuth, clientInfo]); //* the first launch of the application by the client

  if (!openRoute && ((!clientAuth && clientSequredPage) || pathname === '/')) {
    if (!store.getState().common.modal?.name) {
      sessionStorage.removeItem('modalFirst');
    }

    if (BkShortLinkPage) {
      sessionStorage.setItem('showBkUnauthError', true);
    }

    history.push(CLIENT_ROUTES.CLIENT_LOGIN.route);
  }

  let prevPathname = '';

  useEffect(() => {
    if (!clientAuth?._id || prevPathname === pathname) return;

    prevPathname = pathname;

    const pageName =
      clientRoutesArr.find((item) => item?.route?.includes(pathname?.replace('/', '')?.split('/')?.[0]))?.pathname ??
      CLIENT_ROUTES.NOT_FOUND.pathname;
    const deviceType = window?.ReactNativeWebView ? 'app' : 'web';

    request(
      '/statistic/addClientPageStats',
      {
        pageName,
        deviceType,
      },
      () => {},
      () => {},
    );
  }, [pathname]);

  useEffect(() => {
    if (inviteLinkToken && clientAuth._id) {
      setInvitedClient();
    }
  }, [inviteLinkToken, clientAuth._id]);

  if (openRoute) return null;

  return null;
}
