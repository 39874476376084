import { QUESTIONNAIRE_HOTLINE } from 'common_constants/modals';
import { CLIENT_ROUTES } from 'common_constants/routes';
import { setModal } from '../../store/commonReducer';

const checkQueryParams = (clientAuth, history, dispatch) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('makeAppointment')) {
    if (clientAuth) history.push(CLIENT_ROUTES.CLIENT_HOTLINE.route);
    else localStorage.setItem('makeAppointment', 'true');
  }
  const qualityAssessment = urlParams.get('qualityAssessment');
  if (qualityAssessment) {
    if (clientAuth) dispatch(setModal({ name: QUESTIONNAIRE_HOTLINE, data: { H: qualityAssessment } }));
    else localStorage.setItem('qualityAssessment', qualityAssessment);
  }
  window.history.replaceState({}, document.title, window.location.pathname);
};

export default checkQueryParams;
